import React, {  } from 'react';
import Container from 'components/Container';
import { Link } from 'gatsby';

import logo from 'assets/images/logo.svg';

export default function Header() {
  return (
    <header className="header-container noselect">
      <Container type="content">
        <Link to='/'>
          <img className="logo" src={logo} alt="Logo" />        </Link>
          <span className="site-name">Welly Donut Map</span>
      </Container>
    </header>
  );
};