import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";
import "typeface-arvo";
import "typeface-montserrat";
import Div100vh from 'react-div-100vh'


import 'assets/stylesheets/application.scss';

import Header from 'components/Header';
import Footer from 'components/Footer';


const Layout = ({ children, pageName }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  let className = '';

  if ( pageName ) {
    className = `${className} page-${pageName}`;
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: className }}>
        <title>{ data.site.siteMetadata.title }</title>
      </Helmet>
      <Div100vh className="layout-wrapper">
        <Header />
        <div className="body-container">
          { children }
        </div>
        <Footer />
      </Div100vh>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string
};

export default Layout;
